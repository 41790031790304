<template>
  <div>
    <el-dialog :visible.sync="showMessage" width="80%">
      <div slot="title">微信内无法下载APP</div>
      <div>请点击右上角菜单选择【在浏览器中打开】进行下载</div>
    </el-dialog>
    <el-dialog :visible.sync="isIos" width="80%">
      <div class="text-align-center">
        <div class="c-app-yellow">请使用手机扫码下载APP</div>
        <img src="../assets/images/download_app_ios.jpg" alt="" class="w-250 h-250">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import util from '@/libs/util';

export default {
  name: "preDownload",
  data() {
    return {
      showMessage: false,
      isIos:false
    }
  },
  mounted() {
    if (util.inWechat()) {
      if (util.isIos()) {
        this.isIos = true
      } else {
        this.isIos = false
        this.showMessage = true;
      }
    } else {
      this.$loading();
      setTimeout(() => {
        if (util.isIos()) {
          this.isIos = true
        } else {
          var a = document.createElement('a');
          a.setAttribute('href', "/jingtan-app.apk?h=" + (new Date().getHours()));
          a.setAttribute('download', "jingtan-app.apk");
          document.body.appendChild(a);
          a.click();
          setTimeout(() => {
            document.body.removeChild(a)
          }, 400)
        }
      }, 2000);
    }
  }
}
</script>

<style scoped>

</style>
